import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/users`;

const login = (bodyParams) => {
  return axios.post(`${API_URL}/sign_in`, bodyParams);
};

const logout = (bodyParams) => {
  return axios.delete(`${API_URL}/sign_out`, bodyParams);
};

const sendOtp = (bodyParams) => {
  return axios.post(`${API_URL}/send_otp`, bodyParams);
};

const codeVerification = (bodyParams) => {
  return axios.post(`${API_URL}/verify_otp_for_forgot_password`, bodyParams);
};

const resetPassword = (bodyParams) => {
  return axios.patch(`${API_URL}/password`, bodyParams);
};

const setupPassword = (bodyParams) => {
  return axios.patch(`${API_URL}/invitation`, bodyParams);
};

const AuthServices = {
  login,
  sendOtp,
  codeVerification,
  resetPassword,
  setupPassword, logout
}

export default AuthServices;
