import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/users`;
const APP_URL = `${process.env.REACT_APP_API_URL}`;

const getAllUsers = (headerData) => {
	return axios.get(`${API_URL}/list_user`, headerData);
};

const getUser = (headerData, userId) => {
	return axios.get(`${API_URL}/${userId}/profile`, headerData);
};

const createUser = (headerData, bodyData) => {
	return axios.post(`${API_URL}/create_user`, bodyData, headerData);
};

const createTCUser = (headerData, bodyData) => {
	return axios.post(`${API_URL}/create_tc_user`, bodyData, headerData);
};

const updateUser = (headerData, userId, bodyData) => {
	return axios.patch(`${API_URL}/${userId}/update_user`, bodyData, headerData);
};

const deleteUser = (headerData, userId) => {
	return axios.delete(`${API_URL}/${userId}/delete_user`, headerData);
};
const stateApi = (headerData) => {
	return axios.get(`${API_URL}/states`, headerData);
};
const citiesApi = (headerData, cityCode) => {
	return axios.get(`${API_URL}/cities?state_code=${cityCode}`, headerData);
};
const invalidDatesApi = (headerData, assign_to) => {
	return axios.get(
		`${APP_URL}/meets/invalid_dates?assign_to=${assign_to}`,
		headerData,
		{ params: { invalid_dates: assign_to } },
	);
};

const UploadUsers = (headerData, bodyData) => {
  return axios.post(`${APP_URL}/create_user`, bodyData, headerData);
};


const UserServices = {
	getAllUsers,
	getUser,
	createUser,
	updateUser,
	deleteUser,
	stateApi,
	citiesApi,
	invalidDatesApi,
	createTCUser,
  UploadUsers
};

export default UserServices;
