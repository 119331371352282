import moment from "moment-timezone";
export const parseDate = (date, time_zone) => {
	let time = moment(date);
	let formatTime = time?.tz(time_zone)?.format("MMM DD, YYYY");
	return formatTime;
};
export const parseStartDate = (date, time_zone) => {
	let time = moment(date);
	let formatTime = time.tz(time_zone).format("MMM DD");
	return formatTime;
};
export const parseTime = (time, timezone) => {
	let timezoneevents = moment(time);
	let BrowserTimeZone = timezoneevents?.tz(timezone)?.format("HH:mm");
	return BrowserTimeZone;
};

export const parseTimeTwelve = (time, timezone) => {
	let timezoneevents = moment(time);
	let BrowserTimeZone = timezoneevents?.tz(timezone)?.format(" hh:mm A ");

	return BrowserTimeZone;
};
export const parseMeetTime = (time, timezone) => {
	let timezoneformeet = moment(time);
	let formatTime = timezoneformeet?.tz(timezone)?.format("HH:mm");
	return formatTime;
};
export const parseDateTable = (tableDate, timezone) => {
	var dec = moment(tableDate);
	let formatTime = dec.tz(timezone).format("YYYY-MM-DD");

	return formatTime;
};
export const parseAddEventTable = (addTime, timezone) => {
	let timezoneformeet = moment(addTime);
	let formatTime = timezoneformeet.tz(timezone).format("yyyy-MM-DD HH:mm");
	return formatTime;
};

export const parseAddEventTableTimeChanges = (addTime, timezone) => {
	let timezoneformeet = moment(addTime);
	let formatTime = timezoneformeet.tz(timezone).format();
	return formatTime;
};

export const parseFilterDateFormat = (date) => {
	let time = moment(date);
	let formatDate = time?.format("MMM DD, YYYY");
	return formatDate;
};
