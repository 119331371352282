export default function Boys() {
    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.9111 29.5458C10.6317 29.5458 10.3495 29.4624 10.1038 29.2891C9.47218 28.8428 9.32234 27.9689 9.76872 27.337L22.5015 9.32782C22.9482 8.69659 23.8217 8.54605 24.4537 8.99277C25.0852 9.43915 25.2347 10.313 24.7887 10.9446L12.0556 28.9541C11.7832 29.3399 11.3505 29.5458 10.9111 29.5458Z" fill="#4E43B6"/>
                <path d="M30.3541 16.9236H24.5095C23.8216 16.9236 23.2355 16.424 23.127 15.7448L22.2626 10.3578C22.1397 9.59427 22.66 8.87586 23.4232 8.75333C24.1857 8.63149 24.9052 9.15034 25.0277 9.91426L25.703 14.1228H30.3541C31.1275 14.1228 31.7545 14.7498 31.7545 15.5232C31.7545 16.2966 31.1275 16.9236 30.3541 16.9236Z" fill="#4E43B6"/>
                <path d="M18.8079 29.5457C18.5285 29.5457 18.2464 29.4624 18.0006 29.2891C17.369 28.8427 17.2195 27.9689 17.6655 27.3369L21.4865 21.9328L20.6025 21.5148C19.9034 21.1843 19.6044 20.3493 19.9352 19.6501C20.2661 18.951 21.1 18.6523 21.7999 18.9828L24.1914 20.1137C24.5653 20.2908 24.8419 20.6237 24.9473 21.0243C25.0523 21.4244 24.9749 21.8501 24.7362 22.1883L19.9527 28.954C19.6797 29.3398 19.2469 29.5457 18.8079 29.5457Z" fill="#4E43B6"/>
                <path d="M13.9299 14.6265C13.6505 14.6265 13.3683 14.5432 13.1226 14.3696C12.491 13.9232 12.3411 13.0493 12.7875 12.4178L16.7895 6.757C17.1823 6.20174 17.9165 6.00849 18.532 6.29942L20.5779 7.26675C21.2771 7.59724 21.5761 8.43223 21.2452 9.13138C20.9151 9.83053 20.0808 10.1299 19.3806 9.79902L18.3979 9.33444L15.0744 14.0352C14.802 14.4203 14.3693 14.6265 13.9299 14.6265Z" fill="#4E43B6"/>
                <path d="M27.0009 9.00642C28.3432 9.00642 29.4313 7.91829 29.4313 6.57602C29.4313 5.23375 28.3432 4.14563 27.0009 4.14563C25.6587 4.14563 24.5706 5.23375 24.5706 6.57602C24.5706 7.91829 25.6587 9.00642 27.0009 9.00642Z" fill="#4E43B6"/>
                <path d="M12.8856 18.1804H0.945805C0.559295 18.1804 0.245605 17.8667 0.245605 17.4802C0.245605 17.0937 0.559295 16.78 0.945805 16.78H12.8856C13.2721 16.78 13.5858 17.0937 13.5858 17.4802C13.5858 17.8667 13.2721 18.1804 12.8856 18.1804Z" fill="#4E43B6"/>
                <path d="M8.65711 14.2338H0.945805C0.559295 14.2338 0.245605 13.9201 0.245605 13.5336C0.245605 13.1471 0.559295 12.8334 0.945805 12.8334H8.65711C9.04362 12.8334 9.35731 13.1471 9.35731 13.5336C9.35731 13.9201 9.04397 14.2338 8.65711 14.2338Z" fill="#4E43B6"/>
                <path d="M11.758 10.2871H3.7649C3.37839 10.2871 3.0647 9.97343 3.0647 9.58692C3.0647 9.20041 3.37839 8.88672 3.7649 8.88672H11.7584C12.1449 8.88672 12.4586 9.20041 12.4586 9.58692C12.4586 9.97343 12.1449 10.2871 11.758 10.2871Z" fill="#4E43B6"/>
                <path d="M9.48238 22.1267H3.7649C3.37839 22.1267 3.0647 21.813 3.0647 21.4265C3.0647 21.04 3.37839 20.7263 3.7649 20.7263H9.48238C9.86889 20.7263 10.1826 21.04 10.1826 21.4265C10.1826 21.813 9.86889 22.1267 9.48238 22.1267Z" fill="#4E43B6"/>
            </svg>
    
}