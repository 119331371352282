import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/meets`;
const getMeetResult = ( meetId) => {
    return axios.get(`${API_URL}/meet_result?meet_id=${meetId}` )
}
const getEventResult = ( eventID) => {
    return axios.get(`${API_URL}/event_result?event_id=${eventID}` )
}
const getAllmeets = ( bodyData) => {
    return axios.get(`${API_URL}/result_meet_list?page=${bodyData.page}&city=${bodyData.city}&state=${bodyData.state}&date=${bodyData.date}&status=${bodyData.status}` )
}
const getAllEvents = ( bodyData) => {
    return axios.get(`${API_URL}/result_event_list?page=${bodyData.pageno}&meet_id=${bodyData.meetid}&age=${bodyData.age}&sex=${bodyData.sex}&event_name=${bodyData.event_name}` )
}
const getParticularResult = ( bodyData) => {
    return axios.get(`${API_URL}/event_result?meet_id=${bodyData.meet_id}&event_name=${bodyData.event_name}&age=${bodyData.age}&sex=${bodyData.sex}` )
}
const getEventHeatsResult = ( bodyData) => {
    return axios.get(`${API_URL}/result_event_heats?event_id=${bodyData.event_id}` )
}
const getEventHeatsList = ( bodyData) => {
    return axios.get(`${API_URL}/results/heats_list?meet_id=${bodyData.meet_id}&event_name=${bodyData.event_name}&age=${bodyData.age}&sex=${bodyData.sex}` )
}
const downloadEventResultData = (bodyData) => {
	return axios.get(`${API_URL}/event_result_excel?meet_id=${bodyData.meet_id}&event_result_ids=${bodyData.event_result_ids}live-time&type=${bodyData.type} `, {
		...bodyData,
		responseType: "blob",
	});
};

const ResultServices = {
    getMeetResult,
    getEventResult,
    getAllEvents,
    getAllmeets,
    getParticularResult,
    getEventHeatsResult,
    getEventHeatsList,
    downloadEventResultData
}

export default ResultServices;