
import {parseTime} from "../../components/TimeZone"
import customBannerImg from "../../assets/images/Meetimage.png"
import boysevent from "../../assets/images/running.svg"
import girlsevent from "../../assets/images/girl-sports.svg"
import { BoysIcon, GirlsIcon } from "../icons"

export const table1_coloms = [
   
    {
        Header: 'Meet Name',
        accessor: 'name',
        Cell: ({ row }) => (
            <div className="d-flex  align-items-center">
                <div className="mr-2">{<img src={row.original.banner_image ? `${process.env.REACT_APP_BANNER_URL}${row.original.banner_image}` : customBannerImg} alt="customerbannerImage" className='mr-2 br-0' />}</div>
                <div>{row.values.name}</div>
            </div>
        ),
    },
    {
        Header: 'Date',
        accessor: 'date',
    },
    {
        Header: 'Location',
        accessor: 'city_state'
    },
    {
        Header: 'Status',
        accessor: 'status',
         Cell: ({ row }) => (
            <div>
                <div>{row.values.status==="OnGoing" || row.values.status==="Completed" ?row.values.status==="OnGoing"?"Live":"Finished": row.values.status.charAt(0).toUpperCase() + row.values.status.slice(1).toLowerCase()}</div>
            </div>
        ),
    }

]
export const table2_coloms = [
    {
        Header: 'Event Name',
        accessor: 'name',
Cell: ({ row }) => (
            <div className="d-flex  align-items-center">
                <div className="mr-2">{row.values.sex === "Girls"? <GirlsIcon /> : <BoysIcon/> }</div>                
                <div>{row.values.name}</div>     
            </div>
        ),
    },
    {
        Header: 'Age Group',
        accessor:'age',
        Cell: (row) => {
            const cellStyle = {
              paddingLeft: row.value === null || row.value === "" ? '35px' : '10px'
            };
        
            return (
              <div style={cellStyle}>
                {row.value === "" || row.value === null ? "-" : row.value}
              </div>
            );
        }
    },
    {
        Header:'Sex',
        accessor:'sex',
    },
    {
        Header: 'Time',
        accessor: 'event_time',
    },
    {
        Header: 'Status',
        accessor: 'status',
 Cell: ({ row }) => (
            <div>
                <div>{row.values.status==="OnGoing"?"Live":"Finished" }</div>
            </div>
        ),
    },

]
export const table3_coloms = [
    {
        Header: 'Place',
        accessor: 'place',
        Cell: (props) => {
            return (props.value === null ? "-" : props.value)
        }
    },
    {
        Header: 'Runner ID',
        accessor: 'runner_id',
        Cell: (props) => {
            return (props.value === null ? "-" : props.value)
        }
    },
    {
        Header: 'Lane',
        accessor: 'lane'
    },
    {
        Header: 'Heats',
        accessor: 'heats'
    },
    {
        Header: 'First Name',
        accessor: 'first_name',
        Cell: (props) => {
            const cellStyle = {
              paddingLeft: props.value !== null ? '0px' : '20px'
            };
        
            return (
              <div style={cellStyle}>
                {props.value === null ? "-" : props.value}
              </div>
            );
        }
    },
    {
        Header: 'Last Name',
        accessor: 'last_name',
        Cell: (props) => {
            const cellStyle = {
              paddingLeft: props.value !== null ? '0px' : '20px'
            };
        
            return (
              <div style={cellStyle}>
                {props.value === null ? "-" : props.value}
              </div>
            );
        }
    },
    {
        Header: 'Affiliation',
        accessor: 'affiliation',
        Cell: (props) => {
            const cellStyle = {
              paddingLeft: props.value !== null ? '0px' : '40px'
            };
        
            return (
              <div style={cellStyle}>
                {props.value === null ? "-" : props.value}
              </div>
            );
        }
    },
    {
        Header: "Time",
        accessor: 'time',
        Cell: (props) => {
          const cellStyle = {
            paddingLeft: props.value !== null ? '0px' : '22px'
          };
          return (      
            <div style={cellStyle} title={`${props.row.original.actual_time}`}>
              {props.value === null ? "-" : props.value}
            </div>
          );
        }
    }    
   
]
export const table4_coloms = [
    {
        Header: 'Event Name',
        accessor: 'meet_event_name'
    },
    {
        Header:'Heats',
        accessor:'heats'
    },
    {
        Header: 'Round Type',
        accessor:'round_type'
    },
    {
        Header: 'Start Time',
        accessor: 'time',
    }

]