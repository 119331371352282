import React, { useEffect } from 'react';
import { AuthContext } from '../../context/AuthProviderContext';
import EventHeatsTable from '../../components/Table/EventHeatsTable';
import { useParams } from 'react-router-dom';
import MeetServices from '../../services/MeetServices';
import useScreenType from "react-screentype-hook";
import { GoogleAdNew } from '../../components/GoogleAdNew';

const EventHeats = () => {
    const { result,finalEventValues } = AuthContext();
    const { id } = useParams();
    const screenType = useScreenType({
        mobile: 400,
        tablet: 800,
        desktop: 1000,
        largeDesktop: 1600,
      });

    return (
        <div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 grid-margin">
                        <div className="card">
                            <div className="card-body">
                                <div className=" d-flex justify-content-center">
                                    <div className="page-header">
                                        <h4 className="page-title-1">
                                            <b>{screenType.isMobile ? "GTSA Results" : "Go Time Sports Results"}</b></h4>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="page-title mb-4">
                                        <div className='d-flex flex-column justify-content-center align-self-center '>
                                            <div className=' align-self-center '>
                                                <h3 className="mb-1">{finalEventValues?.meet_event_name}
                                                </h3>
                                            </div>
                                            <div className=' align-self-center '>

                                                <h5 className="city-name">
                                                    {result.city}-{result.state}
                                                </h5>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <EventHeatsTable value={id} />
                            </div>
                            {/* <div>
                                <GoogleAdNew />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventHeats

