const TokenValidation = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    let unix_timestamp = JSON.parse(jsonPayload)
    let unix_value = unix_timestamp.exp
    let current_time_stamp = Math.floor(new Date().getTime() / 1000.0)
    if (unix_value > current_time_stamp) {
        return true
    } else {
        return false
    }
}

export default TokenValidation