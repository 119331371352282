import React, { useMemo, useEffect } from 'react'
import { useTable } from "react-table"
import { table4_coloms } from './TableColumns'
import { useNavigate } from 'react-router-dom'
import ResultServices from '../../services/ResultService'
import Table from 'react-bootstrap/Table'
import ReactPaginate from "react-paginate";
import { useState } from 'react'
import ThreeDots from '../Loaders/ThreeDots'
import ResultTableLoader from '../Loaders/ResultTableLoader'
import { AuthContext } from '../../context/AuthProviderContext'
import "../../assets/styles/_result.scss"
const EventHeatsTable = (value) => {
    const navigate = useNavigate()
    const [pageNum, setPageNum] = useState(0);
    const {eventHeatsValue,setFinalEventValues} = AuthContext();
    const [pageCount, setPageCount] = useState(0);
    const [eventHeats, setEventHeats] = useState([])
    const [loader, setLoader] = useState(true)
    const [isMobile, setIsMobile] = useState(false)
    const columns = useMemo(() => table4_coloms, [])
    const data = useMemo(() => eventHeats, [eventHeats])
    const tableInstance = useTable({
        columns,
        data,
    })

    const {
        getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
    } = tableInstance
    const getResult = (props) => {
        navigate("event/") 
    }
    const getEventHeatsData = async () => {
        let pageNumber = pageNum + 1
        let data = {
            event_id:eventHeatsValue?.value,
            pageno: pageNumber
        }
        await ResultServices.getEventHeatsResult(data)
            .then((response) => {
                let res = response?.data?.event_results
                res.map((r) => {
                    let resObject = new Object();
                    resObject.heats = r?.heats;
                    resObject.round_type = r?.round_type;
                    resObject.meet_event_id=r?.meet_event_id;
                    resObject.meet_event_name=r?.meet_event_name;  
                    return setFinalEventValues(resObject);
                  });
                setEventHeats(response.data.event_results)
                setLoader(false)
            })
    }
    const handlePageClick = (e) => {
  
        setPageNum(e.selected);
    };
    useEffect(() => {
        getEventHeatsData()
    }, [pageNum])
    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })
    return (
        <>
            {loader ? (isMobile ? <ThreeDots /> : <ResultTableLoader />) :
                <div>
                    <div className='table-p'>
                        <table className="table table-hover table-responsive-sm" {...getTableProps()}>
                            {/* <Table striped bordered hover {...getTableProps()} responsive="sm"> */}
                            {/* <table {...getTableProps()} class="table table-striped"> */}
                            <thead className='tableheader'>
                                {
                                    headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map((column) => (
                                                    <th {...column.getHeaderProps()} scope="col">
                                                        {column.render('Header')}
                                                    </th>
                                                ))
                                            }

                                        </tr>
                                    ))
                                }

                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {
                                    rows.map(row => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()} onClick={() => getResult(row.original)} style={{ cursor: "pointer" }}>
                                                {
                                                    row.cells.map(cell => {
                                                        return <td style={{ width: "100px" }} {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    })
                                                } 

                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                        {/* </Table> */}
                    </div>
                    <div className="mt-2 pagination-class">
                        <ReactPaginate
                            breakLabel="..."
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            nextLabel={pageCount !== pageNum + 1 ? " Next >" : ""}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            forcePage={pageNum}
                            previousLabel={pageNum !== 0 ? "< Prev" : ""}
                            renderOnZeroPageCount={null}
                            pageClassName={"page-item"}
                            activeClassName={"page-item active-button"}
                            activeLinkClassName={"page-link active-button"}
                            pageLinkClassName={"page-link"}
                            previousClassName={pageNum !== 0 ? "page-item" : ""}
                            previousLinkClassName={pageNum !== 0 ? "page-link" : ""}
                            nextClassName={pageCount !== pageNum + 1 ? "page-item" : ""}
                            nextLinkClassName={pageCount !== pageNum + 1 ? "page-link" : ""}
                            className="mt-4 d-flex align-items-center justify-content-center"
                        />
                    </div>
                </div>
            }
        </>
    )
}

export default EventHeatsTable
