import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "../routes/ProtectedRoute";
import { AuthContext } from "../context/AuthProviderContext";
import TokenValidation from "../components/TokenValidation";
import { useJwt } from "react-jwt";
import EventHeats from "../pages/Results/EventHeats";
const StepperForm = lazy(() => import("../pages/Forms/StepperForm"));
const Login = lazy(() => import("../pages/Authentication/Login"));
const ForgotPassword = lazy(() =>
	import("../pages/Authentication/ForgotPassword"),
);
const CodeVerification = lazy(() =>
	import("../pages/Authentication/CodeVerification"),
);
const ResetPassword = lazy(() =>
	import("../pages/Authentication/ResetPassword"),
);
const SetupPassword = lazy(() =>
	import("../pages/Authentication/SetupPassword"),
);
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const Users = lazy(() => import("../pages/Users/Users"));
const Clubs = lazy(() => import("../pages/Clubs/Clubs"));
const TrackMeets = lazy(() => import("../pages/TrackMeets/TrackMeets"));
const CreateUser = lazy(() => import("../pages/Users/CreateUser"));
const ViewUser = lazy(() => import("../pages/Users/ViewUser"));
const EditUser = lazy(() => import("../pages/Users/EditUser"));
const PastMeets = lazy(() => import("../pages/Meets/PastMeets"));
const UpcomingMeets = lazy(() => import("../pages/Meets/UpcomingMeets"));
const CreateMeet = lazy(() => import("../pages/Meets/CreateMeet"));
const EditMeet = lazy(() => import("../pages/Meets/EditMeet"));
const Profile = lazy(() => import("../pages/Settings/Profile"));
const EventList = lazy(() => import("../pages/Meets/EventList"));
const Faqs = lazy(() => import("../pages/Settings/Faq"));
const Results = lazy(() => import("../pages/Results/Results"));
const Error404 = lazy(() => import("../pages/Errors/Error404"));
const Error500 = lazy(() => import("../pages/Errors/Error500"));
const PastEventList = lazy(() => import("../pages/Meets/PastEventList"));
const CurEventList = lazy(() => import("../pages/Meets/CurEventList"));
const EventTableResult = lazy(() =>
	import("../pages/Results/EventTableResult"),
);
const ResultPage = lazy(() => import("../pages/Results/ResultPage"));
const PrintMeet = lazy(() => import("../pages/Results/Print"));
const TCUserform = lazy(() => import("../pages/Forms/TCUForm"));
const PaymentScreen = lazy(() => import("../pages/Payment/PaymentScreen"));
const AddAthlets = lazy(() => import("../pages/Payment/AddAthlets"));
const SuccessPayment = lazy(() => import("../pages/Payment/SuccessPayment"));
function AppRoutes() {
	const { isLoggedIn, token, setIsLoggedIn } = AuthContext();
	const { isExpired } = useJwt(token);

	// setInterval(() => {
	//   if(isLoggedIn){
	//     if(isExpired){
	//       setIsLoggedIn(false)
	//     }
	//   }
	// }, 50000);

	return (
		<Suspense fallback={null}>
			<Routes>
				<Route exact path='/' element={<Navigate to='/login' />} />
				<Route exact path='/login' element={<Login />} />
				<Route exact path='/forgot_password' element={<ForgotPassword />} />
				<Route exact path='/code_verification' element={<CodeVerification />} />
				<Route exact path='/reset_password' element={<ResetPassword />} />
				<Route
					exact
					path='/api/v1/users/invitation/accept'
					element={<SetupPassword />}
				/>
				<Route
					exact
					path='/dashboard'
					element={
						<ProtectedRoute auth={isLoggedIn}>
							<Dashboard />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/users'
					element={
						<ProtectedRoute auth={isLoggedIn}>
							<Users />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/clubs'
					element={
						<ProtectedRoute auth={isLoggedIn}>
							<Clubs />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/track-meets'
					element={
						<ProtectedRoute auth={isLoggedIn}>
							<TrackMeets />
						</ProtectedRoute>
					}
				/>

				<Route
					exact
					path='/users/create'
					element={
						<ProtectedRoute auth={isLoggedIn}>
							<CreateUser />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/users/TCUser'
					element={
						<ProtectedRoute auth={isLoggedIn}>
							<TCUserform />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/create/uploadtemplate'
					element={
						<ProtectedRoute auth={isLoggedIn}>
							<StepperForm />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/users/:id/view'
					element={
						<ProtectedRoute auth={isLoggedIn}>
							<ViewUser />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/users/:id/edit'
					element={
						<ProtectedRoute auth={isLoggedIn} breadcrumb='User Edit'>
							<EditUser />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/meets/past_meets'
					element={
						<ProtectedRoute auth={isLoggedIn} breadcrumb='Past Meets'>
							<PastMeets />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/meets/upcoming_meets'
					element={
						<ProtectedRoute auth={isLoggedIn} breadcrumb='Upcoming Meets'>
							<UpcomingMeets />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/meet/create'
					element={
						<ProtectedRoute auth={isLoggedIn} breadcrumb='Create Meet'>
							<CreateMeet />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/meets/:id/edit'
					element={
						<ProtectedRoute auth={isLoggedIn} breadcrumb='Edit Meet'>
							<EditMeet />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/meets/:id/event_list'
					element={
						<ProtectedRoute auth={isLoggedIn} breadcrumb='Upcoming Events'>
							<EventList />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/meet/:id/currentevents'
					element={
						<ProtectedRoute auth={isLoggedIn} breadcrumb='Current Events'>
							<CurEventList />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/meets/:id/pastevents'
					element={
						<ProtectedRoute auth={isLoggedIn} breadcrumb='Past Events'>
							<PastEventList />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/settings/profile'
					element={
						<ProtectedRoute auth={isLoggedIn} breadcrumb='Profile'>
							<Profile />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path='/settings/faq'
					element={
						<ProtectedRoute auth={isLoggedIn} breadcrumb='Faq'>
							<Faqs />
						</ProtectedRoute>
					}
				/>

			<Route exact path='/results' element={<Results />} breadcrumb='Result' />

				<Route
					exact
					path='/results/meet/:id'
					element={<EventTableResult />}
					breadcrumb='Meet'
				/>

	{/* <Route
					exact
					path='/results/meet/:id/eventheats'
					element={<EventHeats />}
					breadcrumb='eventheats'
				/> */}

				<Route
					exact
					path='/results/meet/:id/event'
					element={<ResultPage />}
					breadcrumb='event'
				/>
			
				<Route exact path='*' element={<Error404 />} />
				<Route exact path='/errors/500' element={<Error500 />} />
				<Route exact path='/print' element={<PrintMeet />} />
				<Route exact path='/payment' element={<PaymentScreen />} />
				<Route exact path='/payment/add-athlets' element={<AddAthlets />} />
				<Route exact path='/payment/success' element={<SuccessPayment />} />
			</Routes>
		</Suspense>
	);
}

export default AppRoutes;
