import React from 'react';
import { useEffect } from 'react';
export const GoogleAdNew = () => {
    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, [])

    return (
        <ins class="adsbygoogle"
            style={{ display: "inline-block",width:"728px",height:"90px" }}
            data-ad-client="ca-pub-6898148057618290"
            data-ad-slot="4087760498"></ins>
    );
}