export default function Girls() {
    return <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.33271 14.8457C5.19302 14.8457 5.05193 14.804 4.92905 14.7174C4.61326 14.4942 4.53834 14.0573 4.76153 13.7413L11.1279 4.73672C11.3513 4.42111 11.788 4.34584 12.104 4.5692C12.4198 4.79239 12.4945 5.22931 12.2715 5.5451L5.90495 14.5498C5.76876 14.7428 5.5524 14.8457 5.33271 14.8457Z" fill="#86C725"/>
                <path d="M15.0543 8.53455H12.132C11.7881 8.53455 11.495 8.28476 11.4408 7.94516L11.0086 5.25167C10.9471 4.86989 11.2072 4.51069 11.5889 4.44942C11.9701 4.3885 12.3298 4.64792 12.3911 5.02988L12.7288 7.13416H15.0543C15.441 7.13416 15.7545 7.44767 15.7545 7.83436C15.7545 8.22104 15.441 8.53455 15.0543 8.53455Z" fill="#86C725"/>
                <path d="M9.28116 14.8456C9.14147 14.8456 9.00038 14.8039 8.87749 14.7173C8.5617 14.4941 8.48695 14.0572 8.70997 13.7412L10.6205 11.0391L10.1785 10.8301C9.82889 10.6649 9.6794 10.2474 9.84482 9.89781C10.0102 9.54824 10.4272 9.39892 10.7771 9.56417L11.9729 10.1296C12.1599 10.2182 12.2981 10.3846 12.3508 10.5849C12.4034 10.785 12.3647 10.9978 12.2453 11.1669L9.85357 14.5498C9.71703 14.7427 9.50067 14.8456 9.28116 14.8456Z" fill="#86C725"/>
                <path d="M6.84217 7.38603C6.70248 7.38603 6.56139 7.34437 6.43851 7.25754C6.12272 7.03435 6.0478 6.59743 6.27099 6.28164L8.27198 3.45126C8.46839 3.17363 8.83547 3.077 9.14321 3.22246L10.1662 3.70613C10.5158 3.87138 10.6653 4.28887 10.4998 4.63844C10.3348 4.98802 9.91763 5.13769 9.56753 4.97226L9.07616 4.73997L7.41441 7.09037C7.27822 7.28293 7.06186 7.38603 6.84217 7.38603Z" fill="#86C725"/>
                <path d="M13.3777 4.57603C14.0488 4.57603 14.5929 4.03196 14.5929 3.36083C14.5929 2.68969 14.0488 2.14563 13.3777 2.14563C12.7065 2.14563 12.1625 2.68969 12.1625 3.36083C12.1625 4.03196 12.7065 4.57603 13.3777 4.57603Z" fill="#86C725"/>
                <path d="M12.5912 2.85251C12.825 2.85251 13.0146 2.66292 13.0146 2.42906C13.0146 2.1952 12.825 2.00562 12.5912 2.00562C12.3573 2.00562 12.1677 2.1952 12.1677 2.42906C12.1677 2.66292 12.3573 2.85251 12.5912 2.85251Z" fill="#86C725"/>
                <path d="M12.2236 2.27986C11.4358 2.82426 10.8622 2.07488 10.103 2.00083C9.94195 1.98508 9.86528 2.19759 10.0039 2.28109C10.899 2.81971 11.2634 4.13189 12.2868 2.65639" fill="#86C725"/>
                <path d="M6.32 9.16297H0.3501C0.156845 9.16297 0 9.00612 0 8.81287C0 8.61961 0.156845 8.46277 0.3501 8.46277H6.32C6.51326 8.46277 6.6701 8.61961 6.6701 8.81287C6.6701 9.00612 6.51326 9.16297 6.32 9.16297Z" fill="#86C725"/>
                <path d="M4.20575 7.1897H0.3501C0.156845 7.1897 0 7.03286 0 6.8396C0 6.64635 0.156845 6.4895 0.3501 6.4895H4.20575C4.39901 6.4895 4.55585 6.64635 4.55585 6.8396C4.55585 7.03286 4.39918 7.1897 4.20575 7.1897Z" fill="#86C725"/>
                <path d="M5.75619 5.21631H1.75962C1.56636 5.21631 1.40952 5.05947 1.40952 4.86621C1.40952 4.67296 1.56636 4.51611 1.75962 4.51611H5.75636C5.94962 4.51611 6.10646 4.67296 6.10646 4.86621C6.10646 5.05947 5.94962 5.21631 5.75619 5.21631Z" fill="#86C725"/>
                <path d="M4.61835 11.1361H1.75962C1.56636 11.1361 1.40952 10.9793 1.40952 10.786C1.40952 10.5928 1.56636 10.4359 1.75962 10.4359H4.61835C4.81161 10.4359 4.96845 10.5928 4.96845 10.786C4.96845 10.9793 4.81161 11.1361 4.61835 11.1361Z" fill="#86C725"/>
            </svg>
}