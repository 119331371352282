import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./context/AuthProviderContext";
import "./App.scss";
import AppRoutes from "./routes/AppRoutes";
import Navbar from "./pages/Partials/Navbar";
import Sidebar from "./pages/Partials/Sidebar";
import withRouter from "./components/withRouter";
import { GoogleAd } from "./components/GoogleAd";
import axios from "axios";
import TokenValidation from "./components/TokenValidation";
import Breadcrumbs from "./components/BreadCrumbs";
import { BsArrowClockwise } from "react-icons/bs";
import useScreenType from "react-screentype-hook";
import { useJwt } from "react-jwt";
import backWebview from "./assets/images/backWebview.svg";
import { CircularProgress } from "@material-ui/core";
import planLoader from "./assets/gif/webViewLoader.gif";
import DropDown from "./components/DropDown";
// import Spinner from "react-bootstrap/Spinner";
function App(props) {
  const location = useLocation();
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);
  const [navbarComponent, setNavbarComponent] = useState("");
  const { isLoggedIn, token, setIsLoggedIn, stripeLoader } = AuthContext();
  const [sidebarComponent, setSidebarComponent] = useState("");
  const screenType = useScreenType({
    mobile: 400,
    tablet: 800,
    desktop: 1000,
    largeDesktop: 1600,
  });
  const { decodedToken, isExpired } = useJwt(token);
  const navigate = useNavigate();
  useEffect(() => {
    const startTime = new Date().getTime();

    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
      setElapsedTime(elapsedSeconds);
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const formatElapsedTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const formattedSeconds = remainingSeconds % 60;

    if (hours > 0) {
      return `Last updated ${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else if (minutes > 0) {
      return `Last updated ${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else {
      return formattedSeconds > 0 ? "Last updated a few seconds ago" : '';;
    }
  };


  useEffect(() => {
    onRouteChanged(props);
  }, [props]);
  useEffect(() => {
    axios.interceptors.request.use(
      function (config) {
        if (
          props.location.pathname.startsWith("/results") ||
          props.location.pathname.startsWith("/login") ||
          props.location.pathname.startsWith("/forgot_password") ||
          props.location.pathname.startsWith("/code_verification") ||
          props.location.pathname.startsWith("/reset_password") ||
          props.location.pathname.startsWith("/payment")
        ) {
          return config;
        } else if (isLoggedIn) {
          let decoded_token = decodedToken;
          let dateNow = new Date();

          if (decoded_token?.exp * 1000 < dateNow?.getTime()) {
            setIsLoggedIn(false);
            refreshPage();

            return false;
          } else {
            return config;
          }
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }, [props]);

  useEffect(() => {
    setLayout();
  }, [isFullPageLayout]);

  const refreshPage = () => {
    window.location.reload();
  };

  const onRouteChanged = (props) => {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      "/login",
      "/forgot_password",
      "/code_verification",
      "/reset_password",
      "/api/v1/users/invitation/accept",
      "/errors/404",
      "/errors/500",
      "/print",
    ];

    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (props.location.pathname === fullPageLayoutRoutes[i]) {
        setIsFullPageLayout(true);
        document
          .querySelector(".container-fluid")
          .classList.add("full-page-wrapper");
        break;
      } else {
        setIsFullPageLayout(false);
        document
          .querySelector(".container-fluid")
          .classList.remove("full-page-wrapper");
      }
    }
  };

  const setLayout = () => {
    setNavbarComponent(!isFullPageLayout ? <Navbar /> : "");
    setSidebarComponent(
      location.pathname.startsWith("/results") ||
        location.pathname.startsWith("/payment") ? (
        ""
      ) : !isFullPageLayout ? (
        <Sidebar />
      ) : (
        ""
      )
    );
  };

  return (
    <>
      <Toaster position="top-right" />
      {stripeLoader ? (
        <div className="stripeLoader">
          {/* <Spinner animation='border' variant='primary' /> */}
          <img src={planLoader} className="planLoadergif" alt="loader" />
        </div>
      ) : (
        <div
          className={
            location.pathname.startsWith("/payment") && screenType.isMobile
              ? "container-scroller payment-height"
              : "container-scroller"
          }
        >
          {location.pathname.startsWith("/payment") ? (
            <>
              {location.pathname == "/payment" ? (
                <div className="planText ">
                  {" "}
                  <div
                    className="athletes-back"
                    onClick={() => {
                      window?.ReactNativeWebView?.postMessage(
                        JSON.stringify({ message: "goBackToMobile" })
                      );
                    }}
                  >
                    <img src={backWebview} alt="backWebview" />
                  </div>
                  <div className="athletes-title">Choose Your Plan</div>
                </div>
              ) : location.pathname == "/payment/add-athlets" ? (
                <div className="planText ">
                  <div
                    className="athletes-back"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <img src={backWebview} alt="backWebview" />
                  </div>
                  <div className="athletes-title">Subscription Summary</div>
                </div>
              ) : (
                <div className="planText ">Payment</div>
              )}
              <hr className="nomargin"></hr>
            </>
          ) : (
            navbarComponent
          )}

          <div
            className={
              location.pathname.startsWith("/payment")
                ? screenType.isMobile
                  ? "payment-container container-fluid"
                  : "container-fluid  "
                : "container-fluid page-body-wrapper"
            }
          >
            {sidebarComponent}
            <div
              className={
                location.pathname.startsWith("/results") ||
                location.pathname.startsWith("/payment")
                  ? "main-panel-custom"
                  : "main-panel"
              }
            >
              <div
                className={
                  location.pathname.startsWith("/results") ||
                  location.pathname.startsWith("/payment")
                    ? location.pathname == "/payment/add-athlets" &&
                      screenType.isMobile
                      ? "custom-content-wrapper add-athlete-bg"
                      : "custom-content-wrapper "
                    : "content-wrapper"
                } style={{}}
              >
                {location.pathname.startsWith("/results") &&
                  !screenType.isMobile && (
                    <div className="breadcrumbs">
                      <div className="card-body pb-0 p-0">
                        <div className="row">
                          <div className="col-12 ">
                            <div className="card" style={{'border-radius': '0.3125rem 0.3125rem 0rem 0rem'}}>
                              <div className="card-body" style={{ padding: '1rem' }}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <Breadcrumbs />
                                  </div>                                
                                  <div className="d-flex align-items-center">  
                                  <div className='live-time'>{formatElapsedTime(elapsedTime)}</div>                                  
                                    <span className='d-flex' onClick={refreshPage} >             
                                      <BsArrowClockwise className="iconsrefresh" />
                                    </span>
                                    {location.search.startsWith("?age")  &&<div className="ml-2 cursor-pointer">
                                      <DropDown />
                                    </div>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                <AppRoutes />
                {/* {!location.pathname.startsWith("/login") && <GoogleAd />} */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default withTranslation()(withRouter(App));
