import React from 'react';
import { useEffect } from 'react';
export const GoogleAd = () => {
    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, [])

    return (
        <ins className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-6898148057618290"
            data-ad-slot="3834531511"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
    );
}