import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../context/AuthProviderContext';
const Breadcrumbs = () => {
    const { eventvalue,result } = AuthContext();
    const DynamicMeetBreadcrumb = () => (
        <span>{result.name}</span>
    );
    const DynamicEventBreadcrumb = () => (
        <span>{eventvalue}</span>
    );
    const routes = [
        { path: '/results/meet/:id', breadcrumb: DynamicMeetBreadcrumb },
        { path: 'results', breadcrumb: 'Result' },
        { path: '/results/meet/:id/eventheats', breadcrumb: "Heats" },
        { path: '/results/meet/:id/event', breadcrumb: DynamicEventBreadcrumb },
    ];
    const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true });
    return (
        <>
            {breadcrumbs.map(({
                match,
                breadcrumb
            }) => (
                <span key={match.pathname}>
                    <NavLink to={match.pathname}>{breadcrumb} <span>{"/"}</span> </NavLink>
                </span>
            ))}
        </>
    );
};

export default Breadcrumbs