import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

import { AuthContext } from "../../context/AuthProviderContext";
import withRouter from "../../components/withRouter";
import clubsActive from "../../assets/images/clubsActive.svg";
import clubsInActive from "../../assets/images/clubsInactive.svg";
import trackActive from "../../assets/images/trackActive.svg";
import trackInactive from "../../assets/images/trackInactive.svg";
import ResultActive from "../../assets/images/ResultActive.png";
import ResultInactive from "../../assets/images/ResultInactive.png";
import GtsaDownload from "../../assets/images/downloadDeskApp.svg";
function Sidebar(props) {
	const [state, setState] = useState({});
	const { user, handleLogout } = AuthContext();
	const currentYear = new Date().getFullYear();
	const [desktopApp, setDesktopApp] = useState("");
	useEffect(() => {
		onRouteChanged();
		const body = document.querySelector("body");

		document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
			el.addEventListener("mouseover", function () {
				if (body.classList.contains("sidebar-icon-only")) {
					el.classList.add("hover-open");
				}
			});

			el.addEventListener("mouseout", function () {
				if (body.classList.contains("sidebar-icon-only")) {
					el.classList.remove("hover-open");
				}
			});
		});
	}, []);

	useEffect(() => {
		onRouteChanged();
	}, [props]);
	useEffect(() => {
		var baseUrl = window.location.origin.split("/");

		setDesktopApp(baseUrl[2]);
	}, []);
	const onRouteChanged = () => {
		document.querySelector("#sidebar").classList.remove("active");

		Object.keys(state).forEach((i) => {
			setState({ [i]: false });
		});

		const dropdownPaths = [
			{ path: "/meets", state: "meetsPagesMenuOpen" },
			{ path: "/settings", state: "settingsPagesMenuOpen" },
		];

		dropdownPaths.forEach((obj) => {
			if (isPathActive(obj.path)) {
				setState({ [obj.state]: true });
			}
		});
	};

	const isPathActive = (path) => {
		return props.location.pathname.startsWith(path);
	};
	const subPath = (path) => {
		return props.location.pathname.includes(path);
	};

	const toggleMenuState = (menu) => {
		if (state[menu]) {
			setState({ [menu]: false });
		} else if (Object.keys(state).length === 0) {
			setState({ [menu]: true });
		} else {
			Object.keys(state).forEach((i) => {
				setState({ [i]: false });
			});
			setState({ [menu]: true });
		}
	};

	const logout = () => {
		Swal.fire({
			title: "Are you sure want to logout?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No",
		}).then((result) => {
			if (result.isConfirmed) {
				handleLogout();
			}
		});
	};

	return (
		<>
			<nav className='sidebar sidebar-offcanvas' id='sidebar'>
				<ul className='sidenav nav'>
					<li>
						<li
							className={isPathActive("/dashboard") ? "nav-item active" : "nav-item"}>
							<Link className='nav-link' to='/dashboard'>
								<span className='menu-title'>
									<Trans>Dashboard</Trans>
								</span>
								<i className='mdi mdi-view-dashboard menu-icon custom-menu-icon'></i>
							</Link>
						</li>
						{user.role === "Administrator" && (
							<>
								<li className={isPathActive("/users") ? "nav-item active" : "nav-item"}>
									<Link className='nav-link' to='/users'>
										<span className='menu-title'>
											<Trans>Users</Trans>
										</span>
										<i className='mdi mdi-account-multiple menu-icon custom-menu-icon'></i>
									</Link>
								</li>

								<li className={isPathActive("/clubs") ? "nav-item active" : "nav-item"}>
									<Link className='nav-link' to='/clubs'>
										<span className='menu-title'>
											<Trans>Clubs</Trans>
										</span>

										<img
											src={isPathActive("/clubs") ? clubsActive : clubsInActive}
											className='i-img'
											alt='clubsIcon'
										/>
									</Link>
								</li>

								<li
									className={
										isPathActive("/track-meets") ? "nav-item active" : "nav-item"
									}>
									<Link className='nav-link' to='/track-meets'>
										<span className='menu-title'>
											<Trans>Track Meets</Trans>
										</span>
										<img
											src={isPathActive("/track-meets") ? trackActive : trackInactive}
											className='i-img'
											alt='clubsIcon'
										/>{" "}
									</Link>
								</li>
							</>
						)}
						<li className={isPathActive("/meets") ? "nav-item active" : "nav-item"}>
							<div
								className={
									state.meetsPagesMenuOpen ? "nav-link menu-expanded" : "nav-link"
								}
								onClick={() => toggleMenuState("meetsPagesMenuOpen")}
								data-toggle='collapse'>
								<span className='menu-title'>
									<Trans>My Meets</Trans>
								</span>
								<i className='menu-arrow'></i>
								<i className='mdi mdi-run menu-icon custom-menu-icon'></i>
							</div>
							<Collapse in={state.meetsPagesMenuOpen}>
								<ul className='nav flex-column sub-menu'>
									<li className='nav-item'>
										{" "}
										<Link
											className={
												isPathActive("/meets/upcoming_meets") || subPath("/event_list")
													? "nav-link active"
													: "nav-link"
											}
											to='/meets/upcoming_meets'>
											<i className='mdi mdi-clock-in custom-icon'></i>
											<Trans>Upcoming Meets</Trans>
										</Link>
									</li>
									<li className='nav-item'>
										{" "}
										<Link
											className={
												isPathActive("/meets/past_meets") || subPath("/pastevents")
													? "nav-link active"
													: "nav-link"
											}
											to='/meets/past_meets'>
											<i className='mdi mdi-clock-out custom-icon'></i>
											<Trans>Past Meets</Trans>
										</Link>
									</li>
								</ul>
							</Collapse>
						</li>
				        <li
									className={
										isPathActive("/results") ? "nav-item active" : "nav-item"
									}>
									<Link className='nav-link' to='/results' target="blank">
										<span className='menu-title'>
											<Trans>Result</Trans>
										</span>
										<img
											src={isPathActive("/results") ? ResultActive : ResultInactive}
											className='i-img'
											alt='clubsIcon'
											style={{ height: '26px', width: '22px' }}
										/>{" "}
									</Link>
								</li>
						<li
							className={isPathActive("/settings") ? "nav-item active" : "nav-item"}>
							<div
								className={
									state.settingsPagesMenuOpen ? "nav-link menu-expanded" : "nav-link"
								}
								onClick={() => toggleMenuState("settingsPagesMenuOpen")}
								data-toggle='collapse'>
								<span className='menu-title'>
									<Trans>Settings</Trans>
								</span>
								<i className='menu-arrow'></i>
								<i className='mdi mdi-settings menu-icon custom-menu-icon'></i>
							</div>
							<Collapse in={state.settingsPagesMenuOpen}>
								<ul className='nav flex-column sub-menu'>
									<li className='nav-item'>
										{" "}
										<Link
											className={
												isPathActive("/settings/profile") ? "nav-link active" : "nav-link"
											}
											to='/settings/profile'>
											<i className='mdi mdi-face custom-icon'></i>
											<Trans>My Profile</Trans>
										</Link>
									</li>
									<li className='nav-item'>
										{" "}
										<Link
											className={
												isPathActive("/settings/faq") ? "nav-link active" : "nav-link"
											}
											to='/settings/faq'>
											<i className='mdi mdi-comment-text custom-icon'></i>
											<Trans>FAQs</Trans>
										</Link>
									</li>
									<li className='nav-item'>
										{" "}
										<Link
											className={
												isPathActive("/settings/logout") ? "nav-link active" : "nav-link"
											}
											to='#'
											onClick={logout}>
											<i className='mdi mdi-power custom-icon'></i>
											<Trans>Logout</Trans>
										</Link>
									</li>
								</ul>
							</Collapse>
						</li>
					</li>

					<li className=' sidenav-footer'>
						{user.role === "Timing Company Admin" && (
							<div className='nav-item'>
								<a
									className='nav-link '
									href={
										desktopApp.startsWith("gotimesports")
											? " https://gtsa-public.s3.us-west-2.amazonaws.com/GTSA+Setup+1.0.17.zip"
											: " https://gtsa-public.s3.us-west-2.amazonaws.com/GTSA_DEV+Setup+1.0.17.zip"
									}>
									<span className='menu-title downloadApp'>
										<Trans>Download Desktop App</Trans>
									</span>

									<img src={GtsaDownload} className='i-img' alt='app-download' />
								</a>
							</div>
						)}
						<div className='sidenav-footer-content'>
							<div className='text-center  nav-item'>
								<div className='nav-link footer-content  p-1'>
									{" "}
									<span className='menu-title footerNav-size'>
										© {currentYear} GoTimeSports
									</span>
								</div>
							</div>
							<div className='text-center  nav-item'>
								<div className='nav-link footer-content p-2'>
									{" "}
									<span className='menu-title footerNav-size'>
										All rights reserved.{" "}
									</span>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</nav>
		</>
	);
}

export default withRouter(Sidebar);
