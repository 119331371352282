import React, { createContext, useContext, useState } from "react";
import createPersistedState from "use-persisted-state";
import toast from "react-hot-toast";
import AuthServices from "../services/AuthServices";
const useLoginState = createPersistedState("login-state");
const useToken = createPersistedState("bearer-token");
const useMeetData = createPersistedState("meet-data-edit");
const useEventData = createPersistedState("event-data-edit");
const useMeetID = createPersistedState("meetId");
const useResultMeetData = createPersistedState("result-meet");
const useUserName = createPersistedState("username-mail");
const AuthenticatedContext = createContext({});
const useTCchange = createPersistedState("tcadmin");
const useMDAssign = createPersistedState("mdadminassigned");
const useMDchange = createPersistedState("md");
const mdChangesCreate = createPersistedState("mdcreate");
const tcaChangesCreate = createPersistedState("tcacreate");
const UserNav = createPersistedState("user-detail");
const UserChanges = createPersistedState("user-detail-changes");
const eventNameForResult = createPersistedState("event-name-for-result");
const useSelectedEventResults = createPersistedState("selected-event-result");
const useCurrentMeetDetails = createPersistedState("current-Meet-Details");
const usePastMeetDetails = createPersistedState("past-Meet-Details");
const useAthletsCount = createPersistedState("athlets-count");

const AuthProvider = ({ children }) => {
	const [isLoggedIn, setIsLoggedIn] = useLoginState(false);
	const [user, setUser] = useLoginState("");
	const [token, setToken] = useToken("");
	const [username, setUsername] = useUserName("");
	const [forgotToken, setForgotToken] = useToken("");
	const [meetDataEdit, setMeetDataEdit] = useMeetData([]);
	const [editEventList, setEditEventList] = useEventData([]);
	const [meetId, setMeetId] = useMeetID("");
	const [result, setResult] = useResultMeetData([]);
	const [eventvalue, setEventValue] = eventNameForResult("");
	const [usetcAPI, setUseTCAPI] = useTCchange(false);
	const [mdUserAssign, setMDUserAssign] = useMDAssign({});
	const [tcUserValue, setTcUserValue] = useState(null);
	const [useMDAPI, setUseMDAPI] = useMDchange(false);
	const [mdcreatechanges, setMDCreateChanges] = mdChangesCreate(false);
	const [tcacreatechanges, setTCACreateChanges] = tcaChangesCreate(false);
	const [userChange, setUserChange] = UserChanges(false);
	const [navUser, setNavUser] = UserNav({});
	const [selectedEventResults, setSelectedEventResults] =
		useSelectedEventResults({});
	const [currentMeetDetails, setCurrentMeetDetails] = useCurrentMeetDetails({});
	const [pastMeetDetails, setPastMeetDetails] = usePastMeetDetails({});
	const [athletesCount, setAthletesCount] = useAthletsCount("");
	const [files, setFiles] = useState(null);
	const [userUploadFiles, setUserUploadFiles] = useState(null);
	const [userUploadFileName, setuserUploadFileName] = useState(null);
	const [stateName, setStateName] = useState(null);
	const [templateName, setTemplateName] = useState("");
	const [governingName, setGoverningName] = useState(null);
	const [stateCode, setStateCode] = useState(null);
	const [isValidated, setIsValidated] = useState(false);
	const [zeroIsdisabled, setZeroIsDisabled] = useState(true);
	const [disabled, setDisabled] = useState(true);
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
	const [formErrors, setFormErrors] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [eventListTime, setEventListTime] = useState([]);
	const [selectedFile, setSelectedFile] = useState("");
	const [addEnable, setAddEnable] = useState(true);
	const [loader, setLoader] = useState(true);
	const [showAddForm, setShowAddForm] = useState(false);
	const [mdPopup, setMDPopup] = useState(false);
	const [tcPopup, setTCAPopup] = useState(false);
	const [cityName, setCityName] = useState(null);
	const [tableLoader, setTableLoader] = useState(false);
	const [roundType, setRoundType] = useState("");
	const [genderType, setGenderType] = useState("");
	const [eventType, setEventType] = useState(null);
	const [popupAccess, setPopupAccess] = useState(false);
	const [timingCompanyList, setTimingCompanyList] = useState([]);
	const [timingCompName, setTimingCompName] = useState("");
	const [timingCompAdminName, setTimingCompAdminName] = useState(null);
	const [timingCompanyAdminList, setTimingCompanyAdminList] = useState([]);
	const [startTimeLabel, setStartTimeLabel] = useState("");
	const [combinedTime, setCombinedTime] = useState("");
	const [mdList, setMDList] = useState([{}]);
	const [startDate, setStartDate] = useState("");
	const [editstartDate, setEditStartDate] = useState("");
	const [img, setImg] = useState();
	const [assignToName, setAssignToName] = useState(null);
	const [inValidDate, setInvalidDate] = useState([]);
	const [tcUser, setTcUser] = useState([]);
	const [isBackBtnClicked, setIsBackBtnClicked] = useState(false);
	const [timezone, setTimezone] = useState(null);
	const [isMeetDetailsChanged, setIsMeetDetailsChanged] = useState(false);
	const [tz, setTz] = useState("");
	const [timezonedate, setTimezoneDate] = useState(null);
	const [newassignto, setNewAssignto] = useState(null);
	const [newtimezonedate, setNewTimezoneDate] = useState(null);
	const [stripeLoader, setStripeLoader] = useState(false);
	const [MeetDetails, setMeetDetails] = useState({
		governing_body_id: "",
		timing_company_name: "",
		timing_company_id: null,
		timing_company_admin: "",
		timing_company_admin_id: null,
		timing_company_user_id: null,
		timing_company_user: "",
		name: "",
		city: "",
		state: "",
		location_name: "",
		date: "",
		meet_time: "",
		meet_type: "",
		template_id: "",
		user_id: user.id,
		assign_to: null,
		assignee: "",
		selectedTimezone: "",
	});
	const [newMeetDetails, setNewMeetDetails] = useState({
		governing_body_id: null,
		timing_company_name: "",
		timing_company_id: null,
		timing_company_admin: "",
		timing_company_admin_id: null,
		timing_company_user_id: null,
		timing_company_user: "",
		name: "",
		city: "",
		state: "",
		location_name: "",
		date: "",
		meet_time: "",
		meet_type: "",
		template_id: "",
		user_id: user.id,
		assign_to: null,
		assignee: "",
		selectedTimezone: "",
	});
	const [meetTemplate, setMeetTemplate] = useState([]);
	const [governingBodies, setGoverningBodies] = useState([]);
	const [bannerImage, setBannerImage] = useState("");
	const [timed, setTimed] = useState(false);
	const [tempId, setTempId] = useState("");
	const [addEventDetails, setAddEventDetails] = useState({
		name: "",
		event_start_time: "",
		division: "",
		season: "",
		event_type: "",
		age: "",
		event_code: "",
		heats: "",
		sex: "",
		round_type: "",
		max_entry: null,
		total_entry: null,
		event_code_id: 0,
	});
	const [addEventOptions, setAddEventOptions] = useState({
		eventNameOptions: [],
		startTimeOptions: [],
		divisionOptions: [],
		genderOptions: [],
		roundTypeOptions: [],
		eventTypeOptions: [],
		ageGroupOptions: [],
		seasonOptions: [],
	});
	const [editHeats, setEditHeats] = useState("");
	const [activeStep, setActiveStep] = useState(0);
	const [eventList, setEventList] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState({});
	const [filename, setFilename] = useState(null);
	const [isNavigateFromCT, setIsNavigateFromCT] = useState(false);
	const [divisionName, setDivisionName] = useState(null);
	const [seasonName, setSeasonName] = useState(null);
	const [showHeats, setShowHeats] = useState(false);
	const [lastUpdatedAt, setLastUpdatedAt] = useState("");
	const [division, setDivision] = useState([
		{ value: "Youth", label: "Youth" },
		{ value: "High School", label: "High School" },
	]);
	const [season, setSeason] = useState([
		{ value: "Indoor", label: "Indoor" },
		{ value: "Outdoor", label: "Outdoor" },
	]);
	const [newTemplate, setNewTemplate] = useState({
		governing_body_id: "",
		state_id: "",
		name: "",
		season: "",
		division: "",
	});
	const [finalEventValues, setFinalEventValues] = useState(null);
	const [newGoverningName, setNewGoverningName] = useState(null);
	const [eventFiller, setEventFiller] = useState(null)
	const [newStateName, setNewStateName] = useState(null);
	const [newCityName, setNewCityName] = useState(null);
	const [newEventType, setNewEventType] = useState(null);
	const [newstartDate, setNewStartDate] = useState("");
	const [skippedEvents, setSkippedEvents] = useState(0);
	const [skippedEventsList, setSkippedEventsList] = useState(null)
	const [isskippedEventsCheck, setIsSkippedEventsCheck] = useState(false);
	const [pastTime, setPastTime] = useState(null);
	const [values, setValues] = useState({
		username: "",
		password: "",
	});
	const [governingValue, setGoverningValue] = useState(null);
	const [timezonevalue, setTimezoneValue] = useState(null);
	const [eventHeatsValue, setEventHeatsValue] = useState(null);
	const[eventIds,setEventIds]=useState([]);
	const[resultMeetId,setResultMeetId]=useState([])
	const handleLogin = (token, user) => {
		setIsLoggedIn(true);
		setUser(user);
		setToken(token);
	};
	const handleSendCode = (username) => {
		setUsername(username);
	};

	const handleVerifyCode = (forgotToken) => {
		setUsername("");
		setForgotToken(forgotToken);
	};

	const handleResetPassword = () => {
		setForgotToken("");
	};

	const headers = { headers: { Authorization: token } };
	const handleLogout = async () => {
		const bodyData = {
			user: {
				login: values.username,
				password: values.password,
				token: process.env.REACT_APP_DEVICE_TOKEN,
			},
		};
		await AuthServices.logout(headers, bodyData)
			.then((response) => {
				setIsLoggedIn(false);
				setUser("");
				handleCancel();
				setToken("");
				toast.success("Logout success!");
				localStorage.clear();
			})
			.catch((err) => {
				toast.error(err.response.data.message || "Something went wrong!");
			});
	};

	const handleCancel = () => {
		setIsNavigateFromCT(false);
		setBannerImage("");
		setEventList([]);
		setSelectedFile("");
		setMeetDetails({
			governing_body_id: "",
			timing_company_name: "",
			timing_company_admin: "",
			timing_company_id: null,
			timing_company_admin_id: null,
			timing_company_user_id: null,
			name: "",
			city: "",
			state: "",
			location_name: "",
			date: "",
			meet_time: "",
			meet_type: "",
			template_id: "",
			user_id: 0,
			assign_to: 0,
			assignee: "",
			selectedTimezone: "",
		});
		setDivisionName(null);
		setSeasonName(null);
		setNewTemplate({
			name: "",
		});
		setCityName(null);
		setStateName(null);
		setTemplateName("");
		setGoverningName(null);
		setStartDate("");
		setPastTime(null);
		setSkippedEvents(0);
		setSkippedEventsList(null);
		setTimezoneDate(null);
		setEventType(null);
		setMeetTemplate([]);
		setGoverningBodies([]);
		setFiles(null);
		setNewGoverningName(null);
		setNewStateName(null);
		setFilename(null);
		setAddEventDetails({
			name: null,
			event_start_time: "",
			division: "",
			season: "",
			event_type: "",
			age: "",
			event_code: "",
			heats: "",
			sex: "",
			round_type: "",
		});
		setTimingCompName("");
		setTimingCompAdminName(null);
		setAssignToName(null);
		setStartTimeLabel(null);
		setRoundType("");
		setGenderType("");
		setStartDate("");
		setImg("");
		setSelectedEvent({});
		setTcUserValue(null);
		setEventFiller(null)
    setShowAddForm(false)
	};

	return (
		<AuthenticatedContext.Provider
			value={{
				isLoggedIn,
				setIsLoggedIn,
				user,
				setUser,
				season,
				setSeason,
				token,
				divisionName,
				setDivisionName,
				setToken,
				username,
				setUsername,
				division,
				setDivision,
				forgotToken,
				setForgotToken,
				handleLogin,
				seasonName,
				values,
				setValues,
				setSeasonName,
				handleSendCode,
				handleVerifyCode,
				handleResetPassword,
				handleLogout,
				meetDataEdit,
				usetcAPI,
				setUseTCAPI,
				setMeetDataEdit,
				editEventList,
				setEditEventList,
				isMeetDetailsChanged,
				setIsMeetDetailsChanged,
				meetId,
				files,
				setFiles,
				setMeetId,
				result,
				setResult,
				isBackBtnClicked,
				setIsBackBtnClicked,
				useMDAPI,
				setUseMDAPI,
				usetcAPI,
				handleCancel,
				setUseTCAPI,
				filename,
				setFilename,
				userUploadFiles,
				setUserUploadFiles,
				userUploadFileName,
				setuserUploadFileName,
				tcacreatechanges,
				setTCACreateChanges,
				mdcreatechanges,
				setMDCreateChanges,
				finalEventValues,
				setFinalEventValues,
				navUser,
				setNavUser,
				selectedEventResults,
				governingValue,
				setGoverningValue,
				setSelectedEventResults,
				currentMeetDetails,
				tz,
				setTz,
				setCurrentMeetDetails,
				pastMeetDetails,
				setPastMeetDetails,
				userChange,
				isskippedEventsCheck,
				setIsSkippedEventsCheck,
				setUserChange,
				mdUserAssign,
				setMDUserAssign,
				eventvalue,
				newEventType,
				setNewEventType,
				setEventValue,
				isNavigateFromCT,
				activeStep,
				setActiveStep,
				setIsNavigateFromCT,
				newMeetDetails,
				setNewMeetDetails,
				stateName,
				setStateName,
				newCityName,
				setNewCityName,
				templateName,
				setTemplateName,
				newstartDate,
				setNewStartDate,
				governingName,
				setGoverningName,
				stateCode,
				setStateCode,
				newtimezonedate,
				setNewTimezoneDate,
				tcUserValue,
				newassignto,
				setNewAssignto,
				setTcUserValue,
				isValidated,
				setIsValidated,
				zeroIsdisabled,
				setZeroIsDisabled,
				disabled,
				setDisabled,
				states,
				setStates,
				newGoverningName,
				setNewGoverningName,
				cities,
				newStateName,
				setNewStateName,
				setCities,
				formErrors,
				setFormErrors,
				showModal,
				setShowModal,
				skippedEvents,
				setSkippedEvents,
				skippedEventsList,
				setSkippedEventsList,
				eventListTime,
				setEventListTime,
				selectedFile,
				setSelectedFile,
				addEnable,
				setAddEnable,
				loader,
				timezone,
				setTimezone,
				setLoader,
				showAddForm,
				setShowAddForm,
				mdPopup,
				setMDPopup,
				tcPopup,
				setTCAPopup,
				newTemplate,
				setNewTemplate,
				cityName,
				setCityName,
				tableLoader,
				setTableLoader,
				roundType,
				setRoundType,
				genderType,
				setGenderType,
				eventType,
				setEventType,
				popupAccess,
				setPopupAccess,
				timingCompanyList,
				setTimingCompanyList,
				timingCompName,
				setTimingCompName,
				timezonedate,
				setTimezoneDate,
				timingCompAdminName,
				setTimingCompAdminName,
				timingCompanyAdminList,
				setTimingCompanyAdminList,
				startTimeLabel,
				setStartTimeLabel,
				combinedTime,
				setCombinedTime,
				mdList,
				setMDList,
				startDate,
				setStartDate,
				img,
				setImg,
				assignToName,
				setAssignToName,
				inValidDate,
				setInvalidDate,
				tcUser,
				setTcUser,
				MeetDetails,
				setMeetDetails,
				meetTemplate,
				setMeetTemplate,
				governingBodies,
				setGoverningBodies,
				bannerImage,
				setBannerImage,
				timed,
				setTimed,
				timezonevalue,
				setTimezoneValue,
				tempId,
				eventHeatsValue,
				setEventHeatsValue,
				setTempId,
				addEventDetails,
				setAddEventDetails,
				addEventOptions,
				setAddEventOptions,
				editHeats,
				setEditHeats,
				eventList,
				setEventList,
				pastTime,
				setPastTime,
				selectedEvent,
				setSelectedEvent,
				stripeLoader,
				setStripeLoader,
				athletesCount,
				setAthletesCount,
				editstartDate,
				setEditStartDate,
				eventFiller,
				setEventFiller,
				eventIds,
				setEventIds,
				resultMeetId,
				setResultMeetId,
				showHeats, 
				setShowHeats,
				lastUpdatedAt,
				setLastUpdatedAt
			}}>
			{children}
		</AuthenticatedContext.Provider>
	);
};

const AuthContext = () => useContext(AuthenticatedContext);

export { AuthProvider, AuthContext };
