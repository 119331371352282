import { useState, useRef, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FaEllipsisV } from "react-icons/fa";
import "../assets/styles/_result.scss";
import ResultServices from '../services/ResultService';
import { AuthContext } from '../context/AuthProviderContext';
import { useLocation, useSearchParams } from 'react-router-dom';

const DropDown = () => {
  const [typeExcel, setTypeExcel] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const { eventIds, resultMeetId, showHeats, setShowHeats } = AuthContext();
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const dropdownRef = useRef(null);
  const [type, setType] = useState("results"); // Add state for the selected type
  const handleIconClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOutsideClick = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest("#dropdown-icon")
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() =>{
    setShowHeats(false);
  }, []);

  const handleSelect = (eventKey, event) => {
    setSelectedMenuItem(eventKey);
    // Set the type based on the selected menu item
  };

  useEffect(() =>{
    setType(showHeats ? "heats" : "results");
  }, [showHeats]);

  const downloadEvent = async () => {
    let eventData = {
      meet_id: resultMeetId,
      event_result_ids: eventIds,
      type: type, // Pass the selected type
    };

    try {
      const response = await ResultServices.downloadEventResultData(eventData);
  
      // Get the filename from the API response header
      const contentDisposition = response.headers['content-disposition'];
      
      const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+?)"/);
      
      const filename = filenameMatch ? filenameMatch[1] : "GoTimeSports Event Result.xlsx";
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      if (err.response && err.response.status === 401) {
        // Handle unauthorized access
      } else {
        console.error(err.response);
      }
    }
  };

  return (
    <div className="d-flex align-items-center">
      <FaEllipsisV
        onClick={handleIconClick}
        id="dropdown-icon"
        style={{ color: '#007bff' }}
      />
      <Dropdown
        ref={dropdownRef}
        style={{ 'font-family': 'Poppins', position: "relative", right: "170px", top: '10px' }}
        show={showDropdown}
        onSelect={handleSelect}
        onClose={() => setShowDropdown(false)}
      >
        <Dropdown.Menu className="custom-dropdown-menu">
          <Dropdown.Item
            eventKey="1"
            onClick={() => { setShowHeats(!showHeats); setShowDropdown(false) }}
            active={selectedMenuItem === "1"}
          >
            {!showHeats ? "Show as Heats" : "View Results"}
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="2"
            onClick={() => { downloadEvent(); setShowDropdown(false) }}
            active={selectedMenuItem === "2"}
          >
            Download
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropDown;
