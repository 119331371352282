import React, { useState, useEffect } from 'react';
import { Dropdown, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useNavigate, useLocation ,useSearchParams} from 'react-router-dom';
import { AuthContext } from "../../context/AuthProviderContext";
import UserServices from "../../services/UserServices";
import logo from '../../assets/images/logo1.svg';
import gtsa_logo from '../../assets/images/gtsa_logo.svg';
import logoMini from '../../assets/images/logo-mini1.svg';
import profileImage from '../../assets/images/user.png';
import FemaleAvatar from "../../assets/images/female-user.png"
import useScreenType from "react-screentype-hook";
import backWebview from "../../assets/images/backWebview.svg";

function Navbar() {
  const [show, setShow] = useState(false);
  const[searchParams]=useSearchParams()
  // const [gender, setGender] = useState("")
  const screenType = useScreenType({
    mobile: 400,
    tablet: 800,
    desktop: 1000,
    largeDesktop: 1600,
  });
  const navigate = useNavigate()
  const handleClose = () => setShow(false);
  const location = useLocation();
  const[backLogic,setBackLogic]=useState(false)
  const { user, token, navUser, setNavUser, userChange, setUserChange, lastUpdatedAt, setLastUpdatedAt } = AuthContext();
  const headers = { headers: { Authorization: token } };
  const handleShow = () => setShow(true);
  const handleMeetCreate = () => {
    navigate("/create/uploadtemplate")
  }
  useEffect(() => {
    if (!(location.pathname.startsWith("/results") || location.pathname.startsWith("/login") || location.pathname.startsWith('/forgot_password') || location.pathname.startsWith('/code_verification') || location.pathname.startsWith('/reset_password'))) {
      getUserData()

    }
  }, [userChange])

  const getUserData = async () => {
    await UserServices.getUser(headers, user.id)
      .then((response) => {
        setNavUser(response.data.user)
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (searchParams.get("direct_url")) {
      console.log(searchParams.get("direct_url"),location.pathname);
      setBackLogic(true);
    }
    else{
      setBackLogic(false)
      console.log("location.pathName",location.pathname)
    }
  }, [location.pathname]);
  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row ">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo custom-logo custom-cursor-default" to={location.pathname.startsWith("/results") ? "/results" : "/dashboard"}><img src={logo} alt="logo" /></Link>
        {!location.pathname?.startsWith("/results") && <Link className="navbar-brand brand-logo-mini custom-logo-mini" to={location.pathname.startsWith("/results") ? "/results" : "/dashboard"}><img src={logoMini} alt="logo" /></Link>}
        {location.pathname?.startsWith("/results")  && screenType.isMobile && <div onClick={() => {
          location.pathname === "/results"   ? window?.ReactNativeWebView?.postMessage(
            JSON.stringify({ message: "goBackToMobile" })
          ) : backLogic? window?.ReactNativeWebView?.postMessage(
            JSON.stringify({ message: "goBackToMobile" })):navigate(-1)
        }}><img src={backWebview} alt="backWebview" /><i className="fa-solid fa-circle-xmark"></i></div>}
      </div>
      {location.pathname.startsWith("/results") ?
        <div>
          <div className='header_name text-center  d-flex align-items-start'>
            <div className='mobileheaderlogo'><img src={gtsa_logo} alt="logos" /></div>
            <div className='header-name'>Meets & Event Results</div>
          </div>
        </div> :

        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
            <span className="mdi mdi-menu"></span>
          </button>

          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile">
              <Dropdown alignright="true">
                <Dropdown.Toggle className="nav-link custom-profile">
                  {(navUser?.gender === "Female" || navUser?.gender === "Others") ?
                    <div className="nav-profile-img">
                      <img src={FemaleAvatar} alt="user" />
                    </div> :
                    <div className="nav-profile-img">
                      <img src={profileImage} alt="user" />
                    </div>}
                  <div className="nav-profile-text">
                    <p className="mb-1 text-black"><Trans>Hi {navUser?.first_name}</Trans></p>
                  </div>
                </Dropdown.Toggle>
              </Dropdown>
            </li>
            <li className="nav-item">
              {user.role !== "Meet Director" ? <Button variant="primary" onClick={handleShow} disabled={location.pathname === "/create/uploadtemplate"}>
                Create
              </Button> : <Button variant="primary" onClick={handleMeetCreate} disabled={location.pathname === "/create/uploadtemplate"}>
                Create
              </Button>}
            </li>
          </ul>
        </div>
      }
      <Modal className="custom-dialog" show={show} onHide={handleClose}>
        <Modal.Body className="text-center">
          {user.role !== "Meet Director" && (
            <>
              {user.role === "Administrator" && (
                <p className="custom-p">
                  <Link className="btn btn-block btn-primary btn-md font-weight-medium auth-form-btn" to="/users/create" onClick={handleClose}>
                    Create Meet Director{" "}/{" "}TC Admin
                  </Link>
                </p>
              )}
              {user.role === "Timing Company Admin" && (
                <p className="custom-p">
                  <Link className="btn btn-block btn-primary btn-md font-weight-medium auth-form-btn" to="/users/TCUser" onClick={handleClose}>
                    Create Timing Company User
                  </Link>
                </p>
              )}
              <p className="custom-p">
                <Link className="btn btn-block btn-primary btn-md font-weight-medium auth-form-btn" to="/create/uploadtemplate" onClick={handleClose}>
                  Create Meet
                </Link>
              </p>
            </>
          )}

        </Modal.Body>
      </Modal>
      {/* {location.pathname?.startsWith("/results") && screenType.isMobile && <div onClick={() => navigate(-1)}>Back   <i className="fa-solid fa-circle-xmark"></i></div>} */}
    </nav>
  );
}

export default Navbar;